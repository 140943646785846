<template>
  <div class="not-found">
    <!-- <h1>404 - 页面没找到</h1>
    <p>哎呀，页面好像被猫咪叼走了！</p> -->
    <img src="@/assets/404_images/404.png" alt="Cute Cat">
    <br/>
    <router-link to="/"  >返回首页</router-link>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
};
</script>

<style scoped>
.not-found {
  text-align: center;
  padding: 50px;
}
img {
  margin: 20px 0;
  width: 45%;
  min-width: 450px;
}
</style>