import Vue from "vue"
import VueRouter from "vue-router"
import { getToken } from "@/utils/auth"
import store from "@/store"
import cmsDashLayout from "@/layouts/cms-dash-layout"
import cmsBreadLayout from "@/layouts/cms-bread-layout"
import cmsDocLayout from "@/layouts/cms-doc-layout"
import cmsLargeLayout from "@/layouts/cms-large-layout"
import cmsReload from "./cms-reload"
import notFound from "@/layouts/cms-404.vue"

/**
 * 避免同一路由，vuex控制台报错
 */
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

export const routes = [
    {
        path: "/",
        component: cmsDashLayout,
        isTop: true,
        meta: { title: "首页" },
        redirect: "/index",
        children: [
            {
                path: "/index",
                component: () => import(/* webpackChunkName: "index" */ "../views/dash/cms-dash.vue"),
                meta: { title: "", activeMenu: "/" },
            },
        ],
    },
    {
        path: "/path",
        component: cmsDocLayout,
        meta: { title: "学习路径" },
        isTop: true,
        children: [
            {
                path: "",
                component: () => import(/* webpackChunkName: "path" */ "../views/path/cms-path.vue"),
                meta: { title: "列表", reload: true },
                hidden: true,
            },
        ],
    },
    {
        path: "/clanguage",
        component: cmsBreadLayout,
        isTop: true,
        meta: { title: "鹏哥C语言" },
        children: [
            {
                path: "",
                component: () => import(/* webpackChunkName: "clanguage" */ "../views/clanguage/cms-clanguage.vue"),
                meta: { title: "列表" },
                hidden: true,
            },
            {
                path: "detail/:id",
                component: () =>
                    import(/* webpackChunkName: "clanguage" */ "../views/clanguage/cms-clanguage-detail.vue"),
                meta: { title: "详情" },
                hidden: true,
            },
            {
                path: "homework/:id",
                component: () =>
                    import(/* webpackChunkName: "clanguage" */ "../views/clanguage/cms-clanguage-homework.vue"),
                meta: { title: "作业", needLogin: true },
                hidden: true,
            },
        ],
    },
    // {
    //     path: "/doc",
    //     component: cmsDocLayout,
    //     meta: { title: "信息发布" },
    //     isTop: true,
    //     children: [
    //         {
    //             path: "",
    //             component: () => import(/* webpackChunkName: "doc" */ "../views/doc/cms-doc.vue"),
    //             meta: { title: "列表" },
    //             hidden: true,
    //         },
    //         {
    //             path: "detail/:id",
    //             component: () => import(/* webpackChunkName: "doc" */ "../views/doc/cms-doc-detail.vue"),
    //             meta: { title: "详情" },
    //             hidden: true,
    //         },
    //     ],
    // },
    // {
    //     path: "/questions",
    //     component: cmsBreadLayout,
    //     meta: { title: "有问必答" },
    //     isTop: true,
    //     children: [
    //         {
    //             path: "",
    //             component: () => import(/* webpackChunkName: "dashboard" */ "../views/clanguage/cms-clanguage.vue"),
    //             meta: { title: "列表" },
    //         },
    //         {
    //             path: "detail/:id",
    //             component: () => import(/* webpackChunkName: "dashboard" */ "../views/clanguage/cms-clanguage.vue"),
    //             meta: { title: "详情" },
    //         },
    //     ],
    // },
    {
        path: "/biteclass",
        component: cmsBreadLayout,
        meta: { title: "比特就业课" },
        isTop: true,
        children: [
            {
                path: "",
                component: () => import(/* webpackChunkName: "biteclass" */ "../views/bitclass/cms-bitclass.vue"),
                meta: { title: "列表" },
            },
            {
                path: "detail/:id",
                component: () =>
                    import(/* webpackChunkName: "biteclass" */ "../views/bitclass/cms-bitclass-detail.vue"),
                meta: { title: "详情" },
                hidden: true,
            },
        ],
    },
    {
        path: "/qa",
        component: cmsDashLayout,
        meta: { title: "有问必答" },
        isNew: true,
        children: [
            {
                path: "",
                component: () => import(/* webpackChunkName: "doc" */ "../views/qa/cms-qa.vue"),
                meta: { title: "列表" },
                hidden: true,
            },
            {
                path: "detail/:id",
                component: () => import(/* webpackChunkName: "doc" */ "../views/qa/cms-qa-detail.vue"),
                meta: { title: "详情" },
                hidden: true,
            },
        ],
    },
    {
        path: "/aboutus",
        component: cmsDocLayout,
        meta: { title: "关于比特" },
        children: [
            {
                path: "",
                component: () => import(/* webpackChunkName: "aboutus" */ "../views/about/cms-about.vue"),
                meta: { title: "列表", activeMenu: "/aboutus" },
            },
        ],
    },
    {
        path: "/aboutus/more",
        component: cmsLargeLayout,
        hidden: true,
        exact: true,
        meta: { title: "关于比特" },
        children: [
            {
                path: "",
                component: () => import(/* webpackChunkName: "aboutus" */ "../views/about/cms-about-detail.vue"),
                meta: { title: "详情", activeMenu: "/aboutus" },
                hidden: true,
            },
        ],
    },
    {
        path: "/msg",
        component: () => import(/* webpackChunkName: "msg" */ "../views/msg/cms-msg.vue"),
        meta: { title: "消息", needLogin: true, activeMenu: "/" },
        hidden: true,
    },
    {
        path: "/info",
        component: () => import(/* webpackChunkName: "info" */ "../views/user/cms-user.vue"),
        meta: { title: "个人中心", needLogin: true, activeMenu: "/" },
        hidden: true,
    },
    {
        path: "/search",
        component: () => import(/* webpackChunkName: "search" */ "../views/search/cms-search.vue"),
        meta: { title: "搜索", activeMenu: "/" },
        hidden: true,
    },
    {
        path: "/reload",
        component: cmsReload,
        meta: { title: "reload", activeMenu: "/" },
        hidden: true,
    },
    {
        path: "/private",
        meta: { title: "private", activeMenu: "/" },
        component: () => import(/* webpackChunkName: "private" */ "../views/private/cms-private.vue"),
        hidden: true,
    },
    {
        path: "/service",
        meta: { title: "service", activeMenu: "/" },
        component: () => import(/* webpackChunkName: "private" */ "../views/private/cms-service.vue"),
        hidden: true,
    },
    {
        path: "/*", 
        component: notFound, // 404 页面
    },
]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior: () => {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve({ y: 0 })
                const body = document.querySelector("body")
                body.scrollTop = 0
            }, 200)
        })
    },
    routes,
})

router.beforeEach((to, from, next) => {
    // eslint-disable-line
    const { meta } = to
    const { isMenuLoade } = store.getters
    if (isMenuLoade === "init") {
        store.dispatch("GET_Path")
    }
    if (getToken()) {
        const userId = store.getters && store.getters.userId
        if (!userId) {
            store
                .dispatch("GetInfo")
                .then(() => {
                    next({ ...to, replace: true })
                })
                .catch(() => {})
        } else {
            next()
        }
    } else {
        // 是否是需要登录
        if (meta.needLogin == true) {
            store.commit("SET_LOGINPAGE", true)
            // 获取登录的消息，再决定路由的怎么跳转
            next({ ...from, replace: true })
        } else {
            next()
        }
    }
})

export default router
