<template>
    <div class="cms-layout-dash">
        <cms-header></cms-header>
        <router-view />
        <cms-footer></cms-footer>
    </div>
</template>
<script>
import cmsHeader from "./header/cms-header.vue"
import cmsFooter from "./cms-footer.vue"
export default {
    name: "cms-dash-layout",
    components: { cmsHeader, cmsFooter },
}
</script>
<style lang="scss" scoped></style>
